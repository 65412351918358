import React from "react";
import Helmet from "react-helmet";
import "./Home.css"; // Import a CSS file for styling
import Lottie from "react-lottie";
import Header from "../../Header/Header";
import evAnimation from "../../assets/lottie/evAnimation.json";

const Home = () => {
  console.log("Home");
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: evAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="centered-content">
      <Lottie
        options={defaultOptions}
        style={{ width: "17%", height: "30%" }}
      />
      <div className="landing-text">
        <div>
          <h1>Life is unpredictable, and so are your journeys.</h1>
          <div
            id="getWaitlistContainer"
            data-waitlist_id="11301"
            data-widget_type="WIDGET_1"
          ></div>
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"
            />
            <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
          </Helmet>
        </div>

        <p>
          We understand that unexpected situations can leave you stranded with a
          low battery. That's why we're here to provide you with an on-demand
          solution – a reliable and rapid emergency electric vehicle (EV)
          charging service that gets you back on the road when you need it
        </p>
      </div>
    </div>
  );
};

export default Home;
